<template>
    <div class="container">
            <div class="vechile-list-container main-container">
                <div class="vechile-list_row">
                    
                    <div class="result-listing">
                        
                       
                        <div class="result-listing-full-block">
                            <div class="page-title-desc">
                                <h5 class="mb-0">{{ $t('comprehensice_plans') }}</h5>
                            </div>
                            <!-- insert component here -->
                            <div v-if="comprehensives.length > 0">
                                <template v-for="(comprehensive, index) in comprehensives" :key="index">
                                    <QoutesBoxDetails 
                                        :compare="comprehensive"
                                        :indexCheck="index"
                                        :isDetails="true"
                                    />
                                </template>
                            </div>
                            <div v-else>
                                <p v-if="!loading">{{ $t('no_record') }}</p>
                            </div>
                       

                        </div>
                        <div class="result-listing-full-block" v-if="thirdParties.length > 0">
                            <div class="page-title-desc">
                                <h5 class="mb-0">{{ $t('third_party_plans') }}</h5>
                            </div>
                            <!-- insert component here -->
                            <template v-for="(thirdParty, index) in thirdParties" :key="index">
                                <QoutesBoxDetails 
                                    :compare="thirdParty"
                                    :indexCheck="index"
                                    :isDetails="false"
                                 />
                            </template>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store';
import QoutesBoxDetails from '@/layouts/reusable/Qoutes.vue';

export default defineComponent({
    components: {
        QoutesBoxDetails,
    },

    setup() {
        const store = useStore();
        

        // computed
        const comprehensives = computed(() => {
            return store.state.qoutes.comprehensive;
        })
        const loading = computed(() => {
            return store.state.qoutes.isLoading
        })
        const thirdParties= computed(() => {
            return store.state.qoutes.thirdParty;
        })
        const compareData = computed(() => {
            return store.state.qoutes.compareData
        })

        return {
            comprehensives,
            thirdParties,
            compareData,
            loading
        }
    },
})
</script>
