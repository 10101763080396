<template>
    <div class="result-listing-full-block">
        <div class="text-center no-result-block">
        <!-- <h2> {{ $t('no_quotes_we_process') }}  <br /> {{ $t('no_quotes_following') }}
        </h2>   -->
        <h2> {{ $t('we_cannot_process') }} </h2>
        <div class="list"> 
        <ul>
            <li>{{ $t('no_quotes_point_one') }}</li>
            <li>{{ $t('no_quotes_point_two') }}</li> 
        </ul>
        </div>  
        <hr> 
        <p> {{ $t('no_quotes_manual_quote_title') }}
        <a href="https://wa.me/971501465160" target="_blank">
        <img height="25" width="25" src="@/assets/images/icons/whatsapp_footer.svg"></a> 
        <br /> 
        <a
            :href="url">{{ $t('no_quotes_another_car') }}</a> 
        </p> 
        </div>
    </div>
</template>
<script>
import { defineComponent, computed } from "vue";


export default defineComponent({


  setup() {

    const customer_detatils = JSON.parse(window.localStorage.getItem('customer_detatils'));

    const url = computed(() => {
      return customer_detatils.value && customer_detatils.value.customer_source_url ? customer_detatils.value.customer_source_url : '/vehicle-details';
    })

    return {
      url,
      customer_detatils
    };
  },
});
</script>
<style>
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 14.5px !important;
  line-height: 20px !important;
}
</style>
